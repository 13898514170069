import React, { useEffect, useRef, useState } from 'react';
import loginStyle from './review-screens.module.scss';
import style from '../Style/Login.module.css';
import Demo_image from '../assets/loginsettings/demo_image.svg';
import {
  googleLogInHandlerForMobile,
  loginHandlerForMobile,
  errorHandlerForMobile,
  appleLogInHandlerForMobile,
} from '../../utills/validator';
import Mobile_icon from '../assets/loginsettings/mobile_icon.svg';
import Facebook_icon from '../assets/loginsettings/facebook_icon.svg';
import NewLoader from '../../utills/NewLoader';
import Email_icon from '../assets/loginsettings/email_icon.svg';
import Profile_icon from '../assets/loginsettings/profile_icon.svg';
import { Input, Segmented } from 'antd';
import Apple_icon from '../assets/loginsettings/apple_icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { ValidateMobileNumber, checkSuccessCode, validateEmail } from '../../utills/validator';
import { useNavigate } from 'react-router-dom';
import { antdMessageError, antdMessageWarning } from '../common/antdMessageComponent';
// import { setRegisterDetails } from '../../Reducer/commonData';
import ReCAPTCHA from 'react-google-recaptcha';
import { CSCSendEvent, clickAction, eventLocation, eventType } from '../../analytics';
import { useGoogleLogin } from '@react-oauth/google';
import { setRegisterDetails } from '../../Reducer/commonData';
import FacebookLogin from '@greatsumini/react-facebook-login';
import AppleLogin from 'react-apple-login';
import { isValidEmail, isValidNumber } from '../user/utills';
import CTALoader from '../../utills/ctaLoader';

export function NewLogin() {
  const appleLoginButtonRef = useRef(null);
  const queryParams = new URLSearchParams(window.location.search);
  const isGoogleLoginFlow = queryParams.get('useGoogleLogin');
  const isAppleLoginFlow = queryParams.get('useAppleLogin');
  const redirectUrl = queryParams.get('redirectUrl');
  const commanUrlValue = useSelector((state) => state.commanUrlValue);
  const recaptchaRef = useRef(null);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.newClientConfig.login);
  const toggleData = useSelector((state) => state.loginSetting);
  const clientGroupId = useSelector((state) => state.clientGroupId);
  const userDetails = useSelector((state) => state.userDetails);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [recaptcha, setRecaptcha] = useState({
    recaptchaVersion: '',
    recaptchaToken: '',
  });
  const [loading, setLoading] = useState(true);
  const [ctaLoading, setCTALoading] = useState(false);
  const googleIdToken = queryParams.get('google_id_token');
  const loginMethods = toggleData?.loginMethods;
  const accessMethods = toggleData?.accessMethods;
  const userEmail = queryParams.get('email');
  const userPhone = queryParams.get('phone');
  const mobileView = queryParams.get('mobileView') || false;
  const apple_id_token = queryParams.get('apple_id_token');
  const getUserLoginDetailsCallCount = useRef(0);
  const [showAppleLogin, setShowAppleLogin] = useState(true);

  const checkinput = (value) => {
    if (loginMethods?.enableEmailLogin && validateEmail(value.toLowerCase())) {
      setBtnDisabled(false);
      // dispatch(
      //   setRegisterDetails({
      //     ...userDetails,
      //     emailPhone: value,
      //     email: value.toLowerCase(),
      //     primary: 'email',
      //     loginType: 'EMAIL',
      //   }),
      // );
      return dispatch(
        setRegisterDetails({
          ...userDetails,
          emailPhone: value,
          email: value.toLowerCase(),
          loginType: 'EMAIL',
          primary: 'email',
        }),
      );
    }
    if (loginMethods?.enableMobileLogin && ValidateMobileNumber(value)) {
      setBtnDisabled(false);
      // dispatch(
      //   setRegisterDetails({ ...userDetails, emailPhone: value, phone: value, primary: 'phone', loginType: 'PHONE' }),
      // );
      return dispatch(
        setRegisterDetails({ ...userDetails, emailPhone: value, phone: value, loginType: 'PHONE', primary: 'phone' }),
      );
    }
    dispatch(
      setRegisterDetails({ ...userDetails, emailPhone: value, phone: '', email: '', primary: '', loginType: '' }),
    );
    dispatch(setRegisterDetails({ ...userDetails, emailPhone: value, phone: '', email: '', loginType: '' }));
    setBtnDisabled(true);
  };

  useEffect(() => {
    if (validateEmail(userDetails.email)) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      const isAndroid = /android/i.test(userAgent);
      const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
      const isDesktop = !isAndroid && !isIOS;

      if (isAndroid && mobileView) {
        setShowAppleLogin(false); // Hide for Android WebView
      } else {
        setShowAppleLogin(true); // Show for other platforms
      }
    }
  }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    const isAndroid = /android/i.test(userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
    const isDesktop = !isAndroid && !isIOS;

    if (isAndroid && mobileView) {
      setShowAppleLogin(false); // Hide for Android WebView
    } else {
      setShowAppleLogin(true); // Show for other platforms
    }

    if (isAndroid && mobileView) {
      setShowAppleLogin(false); // Hide for Android WebView
    } else {
      setShowAppleLogin(true); // Show for other platforms
    }

    if (validateEmail(userDetails.email)) {
      return setBtnDisabled(false);
    }
    if (ValidateMobileNumber(userDetails.phone)) {
      return setBtnDisabled(false);
    }
  }, []);
  // useEffect(() => {
  //   console.log(toggleData, 'sdkfijkhioerj');
  //   document.title = toggleData.documentTitle ? toggleData.documentTitle : 'SSO Login';
  //   let link = document.querySelector("link[rel~='icon']");
  //   if (!link) {
  //     link = document.createElement('link');
  //     link.rel = 'icon';
  //     document.getElementsByTagName('head')[0].appendChild(link);
  //   }
  //   link.href = toggleData.brandLogoUrl;
  // }, []);
  const clientId = queryParams.get('clientId');
  const purchaseType = queryParams.get('purchaseType');

  useEffect(() => {
    (async () => {
      const userCode = localStorage.getItem('code') || '';
      window.localStorage.setItem('redirectUrl', redirectUrl);
      console.log('testingssoone', userCode);

      if (userCode) {
        try {
          const codedata = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/sso/generate-auth-code`,
            headers: { Authorization: `Bearer ${userCode}` },
          });
          console.log('testingssotwo', redirectUrl);

          if (redirectUrl) {
            if (redirectUrl === 'https://conscent-app-sdk') {
              try {
                const dataformobile = {
                  token: codedata.data.authCode,
                  status: true,
                };
                loginHandlerForMobile(JSON.stringify(dataformobile));
              } catch (err) {
                console.log(err, 'error while sending data to mobile app');
              }
            } else {
              console.log('testingssothree');

              const fetchRedirectUrl = new URL(redirectUrl);
              fetchRedirectUrl.searchParams.set('authorizationCode', codedata.data.authCode);
              if (queryParams.get('origin') === 'subsPage') {
                navigate(`/address?redirectUrl=${redirectUrl}&clientId=${clientId}&purchaseType=${purchaseType}`);
              } else {
                console.log('testingssofour');
                const anonId = queryParams.get('anonId');
                const paywallId = queryParams.get('paywallId');
                fetchRedirectUrl.searchParams.set('anonId', anonId);
                fetchRedirectUrl.searchParams.set('paywallId', paywallId);
                console.log('testingssofive', fetchRedirectUrl.href);

                window.location.href = fetchRedirectUrl.href;
              }
            }
          } else {
            antdMessageError('A redirect URL is required but not provided. Please check and try again.');
          }
        } catch (err) {
          localStorage.removeItem('code');
          const value = JSON.stringify({ message: 'SSO code expired', errorCode: '400' });

          errorHandlerForMobile(value);
          console.log(err, 'error on generating auth code');
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (clientGroupId) {
      if (googleIdToken) {
        try {
          axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/sso/google-one-tap-login`,
            data: {
              token: googleIdToken,
              clientGroupId: clientGroupId,
              clientId,
              deviceType: queryParams.get('deviceCategory'),
            },
          })
            .then((googleLoginResponse) => {
              if (redirectUrl === 'https://conscent-app-sdk') {
                // const dataformobile = {
                //   token: googleLoginResponse.data.authorizationCode,
                //   status: true,
                // };
                localStorage.setItem('code', googleLoginResponse.data.authorizationCode);
                axios({
                  method: 'POST',
                  url: `${process.env.REACT_APP_API_URL}/sso/generate-auth-code`,
                  headers: { Authorization: `Bearer ${googleLoginResponse.data.authorizationCode}` },
                }).then((res) => {
                  const dataformobile = {
                    token: res.data.authCode,
                    status: true,
                  };
                  setLoading(false);
                  loginHandlerForMobile(JSON.stringify(dataformobile));
                });
              } else {
                const fetchRedirectUrl = new URL(redirectUrl);
                localStorage.setItem('code', googleLoginResponse.data.authorizationCode);
                axios({
                  method: 'POST',
                  url: `${process.env.REACT_APP_API_URL}/sso/generate-auth-code`,
                  headers: { Authorization: `Bearer ${googleLoginResponse.data.authorizationCode}` },
                }).then((res) => {
                  fetchRedirectUrl.searchParams.set('authorizationCode', res.data.authCode);

                  window.location.href = fetchRedirectUrl.href;
                  setLoading(false);
                });

                // window.location.href = fetchRedirectUrl.href;
                setLoading(false);
              }
            })
            .catch((error) => {
              console.error('error logging in with google id token', error);
              const fetchRedirectUrl = new URL(redirectUrl);
              fetchRedirectUrl.searchParams.set('loginError', 'error loggging in with google');
              window.location.href = fetchRedirectUrl.href;
              setLoading(false);
            });
        } catch (error) {
          setLoading(false);
          const value = JSON.stringify({ message: 'GOOGLE one tap login error', errorCode: '400' });

          errorHandlerForMobile(value);
          console.error(error);
        }
      } else {
        setLoading(false);
      }
    }
  }, [clientGroupId]);

  const getButtonText = () => {
    let text = 'Get OTP';
    if ((accessMethods?.otp && accessMethods?.password) || accessMethods?.password) {
      text = 'Login';
    }
    return text;
  };
  const navigateHandle = () => {
    if (userDetails.phone) {
      handleLogin();
      return;
    }
    navigate(`/loginemail?${queryParams.toString()}`);
  };

  const handleLogin = async () => {
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/login`,
        data: {
          ...((userDetails?.phone || userPhone) && {
            phone: isValidNumber(userDetails?.phone || userPhone),
            countryCode: '+91',
          }),
          email: isValidEmail(userDetails?.email || userEmail),
          clientGroupId: clientGroupId,
          clientId: commanUrlValue.clientId,
          loginType: 'otp',
          paywallId: commanUrlValue.paywallId,
          paywallType: commanUrlValue.paywallType,
        },
      });
      if (checkSuccessCode(response)) {
        navigate(`/newvarifyotp?${queryParams.toString()}`, {
          state: { backRoute: '/login', email: userDetails?.email, phone: userDetails?.phone },
        });
      }
    } catch (error) {
      return antdMessageError(error?.response?.data?.message);
    }
  };

  const handleTokenv2 = (token) => {
    setRecaptcha({ ...recaptcha, recaptchaToken: token });
  };

  const getUserLoginDetails = async () => {
    setCTALoading(true);
    let token = '';
    if (toggleData?.captcha?.enabled && toggleData?.captcha?.onLogin && toggleData?.captcha?.captchaVersion === 3) {
      token = await recaptchaRef.current.executeAsync();
    }
    try {
      const getDetailsResponse = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/sso/check-account-status`,
        params: {
          clientGroupId: clientGroupId,
          clientId: commanUrlValue.clientId,
          email: isValidEmail(userDetails?.email || userEmail),
          phone: isValidNumber(userDetails?.phone || userPhone),
          ...(token && {
            token: token,
          }),
          ...(recaptcha?.recaptchaToken && {
            token: recaptcha?.recaptchaToken,
          }),
          type: 'onLogin',
        },
      });
      if (checkSuccessCode(getDetailsResponse)) {
        setCTALoading(false);
        const validPassword = getDetailsResponse?.data?.validPassword;
        if (getDetailsResponse?.data?.status) {
          if (getDetailsResponse?.data?.validated) {
            const ssoLoginClickObject = {
              eventType: eventType?.CLICK,
              eventLocation: eventLocation?.SSO_LOGIN,
              clickAction: userDetails?.loginType,
              clientId: commanUrlValue?.clientId,
              anonId: commanUrlValue?.anonId,
              paywallId: commanUrlValue?.paywallId,
              url: decodeURIComponent(window.location.href || ''),
              segmentId: commanUrlValue?.segmentId,
              journeyId: commanUrlValue?.journeyId,
            };
            CSCSendEvent(ssoLoginClickObject);
            if (!validPassword) {
              handleLogin();
            } else {
              getButtonText() === 'Get OTP' ? handleLogin() : navigateHandle();
            }
          } else {
            antdMessageError('The reCAPTCHA token is invalid. Please try again.');
          }
        } else {
          const value = JSON.stringify({
            message: "We couldn't find a registered account. Want to sign up?",
            errorCode: '404',
          });

          errorHandlerForMobile(value);
          antdMessageWarning("We couldn't find a registered account. Want to sign up?");
          navigate(`/personalinfo?${queryParams.toString()}`, { state: '/personalinfo' });
        }
      }
      setCTALoading(false);
    } catch (error) {
      console.log(error);
      setCTALoading(false);
      return antdMessageError(
        'We encountered an issue while checking your registration status. Please try again later.',
      );
    }
  };

  useEffect(() => {
    if (toggleData?.captcha?.captchaVersion === 2) {
      setRecaptcha({ ...recaptcha, recaptchaVersion: 'normal' });
    } else if (toggleData?.captcha?.captchaVersion === 3) {
      setRecaptcha({ ...recaptcha, recaptchaVersion: 'invisible' });
    }
  }, [toggleData?.captcha]);
  const userCode = localStorage.getItem('code') || '';

  useEffect(() => {
    const ssoLoginViewObject = {
      eventType: eventType.VIEW,
      eventLocation: eventLocation.SSO_LOGIN,
      clientId: commanUrlValue?.clientId,
      anonId: commanUrlValue?.anonId,
      paywallId: commanUrlValue?.paywallId,
      url: decodeURIComponent(window.location.href || ''),
      segmentId: commanUrlValue?.segmentId,
      journeyId: commanUrlValue?.journeyId,
    };
    CSCSendEvent(ssoLoginViewObject);
  }, [commanUrlValue]);

  useEffect(() => {
    userEmail && checkinput(userEmail);
    userPhone && checkinput(userPhone);
    if (inputRef.current) {
      inputRef.current.focus();
    }
    if ((userEmail || userPhone) && getUserLoginDetailsCallCount.current < 1) {
      setCTALoading(true);
      getUserLoginDetailsCallCount.current += 1;
      setTimeout(() => {
        setCTALoading(false);
        getUserLoginDetails();
      }, 1000);
    }
  }, [toggleData]);

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const googleLoginResponse = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/google-login`,
        data: {
          token: tokenResponse.access_token,
          clientGroupId: clientGroupId,
          clientId: commanUrlValue.clientId,
        },
      });
      localStorage.setItem('code', googleLoginResponse.data.accessToken);
      const userCode = googleLoginResponse.data.accessToken;
      if (userCode) {
        try {
          const codedata = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/sso/generate-auth-code`,
            headers: { Authorization: `Bearer ${userCode}` },
          });
          if (redirectUrl) {
            if (redirectUrl === 'https://conscent-app-sdk') {
              try {
                const dataformobile = {
                  token: codedata.data.authCode,
                  status: true,
                };
                loginHandlerForMobile(JSON.stringify(dataformobile));
              } catch (err) {
                console.log(err, 'error while sending data to mobile app');
              }
            } else {
              const fetchRedirectUrl = new URL(redirectUrl);
              fetchRedirectUrl.searchParams.set('authorizationCode', codedata.data.authCode);
              if (queryParams.get('origin') === 'subsPage') {
                navigate(`/address?redirectUrl=${redirectUrl}&clientId=${clientId}&purchaseType=${purchaseType}`);
              } else {
                const anonId = queryParams.get('anonId');
                const paywallId = queryParams.get('paywallId');
                fetchRedirectUrl.searchParams.set('anonId', anonId);
                fetchRedirectUrl.searchParams.set('paywallId', paywallId);
                window.location.href = fetchRedirectUrl.href;
              }
            }
          } else {
            antdMessageError('A redirect URL is required but not provided. Please check and try again.');
          }
        } catch (err) {
          localStorage.removeItem('code');
          console.log(err, 'error on generating auth code');
        }
      }
    },
    flow: 'implicit',
  });
  const callGoogleLogin = () => {
    if (mobileView) {
      googleLogInHandlerForMobile(JSON.stringify({ message: 'GOOGLE_LOGIN_CLICK' }));
    } else {
      handleGoogleLogin();
    }
    const ssoLoginClickObject = {
      eventType: eventType.CLICK,
      eventLocation: eventLocation.SSO_LOGIN,
      clickAction: clickAction.GOOGLE,
      clientId: commanUrlValue?.clientId,
      anonId: commanUrlValue?.anonId,
      url: decodeURIComponent(redirectUrl || ''),
      segmentId: commanUrlValue?.segmentId,
      journeyId: commanUrlValue?.journeyId,
    };
    CSCSendEvent(ssoLoginClickObject);
  };
  const handleAppleLogin = async (token) => {
    setLoading(true);
    try {
      const appleLoginResponse = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/apple-login`,
        data: {
          token: token,
          clientGroupId: clientGroupId,
          clientId: commanUrlValue.clientId,
        },
      });
      localStorage.setItem('code', appleLoginResponse.data.accessToken);
      const userCode = appleLoginResponse.data.accessToken;
      if (userCode) {
        try {
          const codedata = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/sso/generate-auth-code`,
            headers: { Authorization: `Bearer ${userCode}` },
          });
          if (redirectUrl) {
            if (redirectUrl === 'https://conscent-app-sdk') {
              try {
                const dataformobile = {
                  token: codedata.data.authCode,
                  status: true,
                };
                loginHandlerForMobile(JSON.stringify(dataformobile));
              } catch (err) {
                console.log(err, 'error while sending data to mobile app');
              }
            } else {
              const fetchRedirectUrl = new URL(redirectUrl);
              fetchRedirectUrl.searchParams.set('authorizationCode', codedata.data.authCode);
              if (queryParams.get('origin') === 'subsPage') {
                navigate(`/address?redirectUrl=${redirectUrl}&clientId=${clientId}&purchaseType=${purchaseType}`);
                setLoading(false);
              } else {
                const anonId = queryParams.get('anonId');
                const paywallId = queryParams.get('paywallId');
                fetchRedirectUrl.searchParams.set('anonId', anonId);
                fetchRedirectUrl.searchParams.set('paywallId', paywallId);
                window.location.href = fetchRedirectUrl.href;
                setLoading(false);
              }
            }
          } else {
            setLoading(false);
            antdMessageError('A redirect URL is required but not provided. Please check and try again.');
          }
        } catch (err) {
          localStorage.removeItem('code');
          console.log(err, 'error on generating auth code');
        }
      }
    } catch (error) {
      if (!checkSuccessCode(error)) {
        setLoading(false);
        antdMessageError(error?.response?.data?.message);
        return errorHandlerForMobile(JSON.stringify(error?.response?.data));
      }
    }
  };

  useEffect(() => {
    if (apple_id_token) {
      handleAppleLogin(apple_id_token);
    }
  }, [apple_id_token]);

  useEffect(() => {
    if (isGoogleLoginFlow) {
      setTimeout(() => {
        callGoogleLogin();
      }, 600);
    }
  }, []);

  useEffect(() => {
    if (isAppleLoginFlow) {
      setTimeout(() => {
        if (appleLoginButtonRef.current) {
          appleLoginButtonRef.current.click();
        }
      }, 600);
    }
  }, []);

  const callFacebookLogin = () => {
    const ssoLoginClickObject = {
      eventType: eventType.CLICK,
      eventLocation: eventLocation.SSO_LOGIN,
      clickAction: clickAction.FACEBOOK,
      clientId: commanUrlValue?.clientId,
      anonId: commanUrlValue?.anonId,
      url: decodeURIComponent(redirectUrl || ''),
      segmentId: commanUrlValue?.segmentId,
      journeyId: commanUrlValue?.journeyId,
    };
    CSCSendEvent(ssoLoginClickObject);
  };
  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      if (!btnDisabled) {
        getUserLoginDetails();
      }
    }
  };

  if (userCode || loading) {
    return <NewLoader />;
  }

  return (
    <div className={loginStyle['main-container']}>
      <div className={loginStyle['header-logo']}>
        <img className={loginStyle['image-logo']} src={toggleData?.brandLogoUrl || Demo_image} alt="Demo_image" />
      </div>
      <div className={loginStyle['p-4']}></div>
      <div className={loginStyle['container']}>
        <div className={loginStyle['child-container']}>
          <div className={loginStyle['heading-container']}>
            <div>
              <div className={loginStyle['heading-text']} style={data?.loginFormTitle}>
                {data?.loginFormTitle?.text || 'Welcome back'}
              </div>
            </div>
          </div>
          <div className={loginStyle['form-container']}>
            {loginMethods?.enableEmailLogin && loginMethods?.enableMobileLogin ? (
              <div className={loginStyle['input-container-common']}>
                <img src={Profile_icon} alt="Profile_icon" />
                <Input
                  placeholder={data?.loginInputText?.text || 'Email / Mobile'}
                  style={data?.loginInputText}
                  ref={inputRef}
                  onChange={(e) => checkinput(e.target.value)}
                  value={userDetails?.emailPhone}
                  onKeyDown={keyDownHandler}
                />
              </div>
            ) : (
              <>
                {loginMethods?.enableMobileLogin && (
                  <div className={loginStyle['input-container']}>
                    <img src={Mobile_icon} alt="Mobile_icon" />
                    <div className={loginStyle['country-code']}>+91</div>
                    <div className={loginStyle['vertical-line']}></div>
                    <Input
                      placeholder={data?.loginInputText?.text || '976537263636'}
                      style={data?.loginInputText}
                      onChange={(e) => {
                        const reg = /^-?\d+(\.\d*)?$/;
                        if (reg.test(e.target.value) || e.target.value === '') {
                          checkinput(e.target.value);
                        }
                      }}
                      value={userDetails?.emailPhone}
                      ref={inputRef}
                      onKeyDown={keyDownHandler}
                    />
                  </div>
                )}
                {loginMethods?.enableEmailLogin && (
                  <div className={loginStyle['input-container-common']}>
                    <img src={Email_icon} alt="Email_icon" />
                    <Input
                      placeholder={data?.loginInputText?.text || 'Email'}
                      style={data?.loginInputText}
                      onChange={(e) => checkinput(e.target.value)}
                      value={userDetails?.emailPhone}
                      ref={inputRef}
                      onKeyDown={keyDownHandler}
                    />
                  </div>
                )}
              </>
            )}
            {toggleData?.captcha?.enabled && toggleData?.captcha?.onLogin && recaptcha?.recaptchaVersion && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size={recaptcha?.recaptchaVersion}
                  sitekey={toggleData?.captcha?.captchaKey}
                  onChange={handleTokenv2}
                />
              </div>
            )}
          </div>
          <button
            className={loginStyle['btn-style']}
            disabled={btnDisabled}
            style={{
              ...data?.buttonText,
              opacity: btnDisabled ? '0.5' : '1',
              cursor: btnDisabled ? 'not-allowed' : 'pointer',
            }}
            onClick={() => {
              getUserLoginDetails();
            }}
          >
            {ctaLoading ? <CTALoader /> : data?.buttonText?.text || getButtonText()}
          </button>
          {loginMethods?.enableGoogleLogin || loginMethods?.enableFacebookLogin || loginMethods?.enableAppleLogin ? (
            <div className={loginStyle['continueWithContainer']}>
              <hr className={loginStyle['horizontalLine']} />
              <div className={loginStyle['continueWith']} style={data?.dividerText}>
                {data?.dividerText?.text || 'Or'}
              </div>
              <hr className={loginStyle['horizontalLine']} />
            </div>
          ) : (
            ''
          )}
          {loginMethods?.enableGoogleLogin && (
            <button className={loginStyle['google-btn']} onClick={() => callGoogleLogin()}>
              <img
                src="https://stage0conscent.blob.core.windows.net/stage-conscent-public/google-logo.svg"
                alt="Google logo"
              />
              <div style={data?.googleSsoText}>{data?.googleSsoText?.text || 'Continue with Google'}</div>
            </button>
          )}
          {/* {loginMethods?.enableFacebookLogin && (
            <FacebookLogin
              appId={'367167408962065'}
              onSuccess={(res) => console.log(res, 'res_Success')}
              onFail={(res) => console.log(res, 'res_Failour')}
              render={({ onClick }) => (
                <button className={loginStyle['facebook-btn']} onClick={onClick}>
                  <img src={Facebook_icon} alt="Facebook_icon" />
                  <div style={data?.facebookSsoText}>{data?.facebookSsoText?.text || 'Continue with Facebook'}</div>
                </button>
              )}
            />
          )} */}
          {loginMethods?.enableAppleLogin && showAppleLogin && (
            <AppleLogin
              clientId={toggleData?.appleServiceId} // Your Service ID
              redirectURI={window.location.origin} // Your Redirect URI
              usePopup={isAppleLoginFlow ? false : true} // or false, depending on your implementation
              scope="name email"
              render={(props) => (
                <button
                  className={loginStyle['google-btn']}
                  style={{ marginTop: '25px' }}
                  ref={appleLoginButtonRef} // Assign the reference to the button
                  onClick={() => {
                    if (mobileView) {
                      appleLogInHandlerForMobile(JSON.stringify({ message: 'APPLE_LOGIN_CLICK' }));
                    } else {
                      props.onClick();
                    }
                  }}
                >
                  <img src={Apple_icon} alt="Apple_icon" />
                  <div style={data?.appleSsoText}>{data?.appleSsoText?.text || 'Continue with Apple'}</div>
                </button>
              )}
              callback={(token) => {
                if (token?.authorization?.id_token) {
                  handleAppleLogin(token?.authorization?.id_token);
                } else {
                  console.error(token?.error?.error);
                }
              }}
            />
          )}
        </div>
        <div
          onClick={() => {
            callGoogleLogin();
          }}
          id="google"
        ></div>
      </div>
    </div>
  );
}
